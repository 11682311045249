// Packages
import { faAlignLeft, faNotEqual, faShare, faTrash, faRemoveFormat, faSave, faDirections, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

// Css
import './../../styles/AdminLTE/box.css';
import './../../styles/diffonline/common.css';
import './Home.css';

// Services
import { diffGetBySlug, diffCreate } from './../../services/Diff';

const oldCode = `const a = 10
const b = 10
const c = () => console.log('foo')

if (a > 10) {
  console.log('bar')
}

console.log('done')`;

const newCode = `const a = 10
const boo = 10

if (a === 10) {
  console.log('bar')
}`;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      oldCode: '',
      newCode: '',
      modalShareStatus: false,
      buttonCopyText: 'Copy',
    }

    // Handle
    this.clear = this.clear.bind(this);
    this.compare = this.compare.bind(this);
    this.copyCurrentURL = this.copyCurrentURL.bind(this);
    this.example = this.example.bind(this);
    this.goToHomePage = this.goToHomePage.bind(this);
    this.modalShareHide = this.modalShareHide.bind(this);
    this.modalShareShow = this.modalShareShow.bind(this);
    this.procedureShareErrorShow = this.procedureShareErrorShow.bind(this);
    this.procedureShareReset = this.procedureShareReset.bind(this);
    this.procedureShareSuccessShow = this.procedureShareSuccessShow.bind(this);
    this.share = this.share.bind(this);
    this.upload = this.upload.bind(this);
  }

  modalShareShow() {
    this.setState({
      modalShareStatus: true,
    });
  }

  modalShareHide() {
    this.setState({
      modalShareStatus: false,
    });
  }

  procedureShareErrorShow(message) {
    this.setState({
      procedureShareError: true,
      procedureShareErrorMessage: message,
    });
  }

  procedureShareSuccessShow(slug) {
    this.setState({
      procedureShareSuccess: true,
      procedureShareSuccessSlug: slug,
    });
  }

  procedureShareReset() {
    return new Promise(resolve => {
      if (this.original) {
        this.original.setReadOnly(false);
      }
      if (this.changed) {
        this.changed.setReadOnly(false);
      }

      this.setState({
        procedureShareStatus: false,
        procedureShareDisabledAllButton: false,
        procedureShareProcessingNow: 0,
        procedureShareError: false,
        procedureShareErrorMessage: '',
        procedureShareSuccess: false,
        procedureShareSuccessSlug: '',
        procedureShareDisableStepClass: '',
        procedureShareDisableStepLabel: 'Disable',
        procedureShareSaveStepClass: '',
        procedureShareSaveStepLabel: 'Save',
        procedureShareRedirectStepClass: '',
        procedureShareRedirectStepLabel: 'Redirect',
      }, () => {
        resolve();
      });
    });
  }

  copyCurrentURL(e) {
    const
      that = this;

    this.inputCurrentURL.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ buttonCopyText: 'Copied!' });
    setTimeout(() => {
      that.setState({ buttonCopyText: 'Copy' });
    }, 5000);
  }

  goToLastLine(editor) {
    if (editor) {
      const
        row = editor.session.getLength() - 1,
        column = editor.session.getLine(row).length;

      editor.gotoLine(row + 1, column);
    }
  }

  initEditor() {
    const
      ace = window.ace;

    this.setState({
      ready: true,
    }, () => {
      if (document.getElementById('original')) {
        this.original = ace.edit("original");
        this.original.setTheme("ace/theme/chrome");
        this.original.session.setMode("ace/mode/text");
        this.original.setOption("wrap", true);
      }

      if (document.getElementById('changed')) {
        this.changed = ace.edit("changed");
        this.changed.setTheme("ace/theme/chrome");
        this.changed.session.setMode("ace/mode/text");
        this.changed.setOption("wrap", true);
      }
    });
  }

  initBySlug(slug) {
    return new Promise(resolve => {
      diffGetBySlug(slug).then(result => {
        if (result.length) {
          const
            { original, changed } = result[0];

          if (original && changed) {
            this.initEditor();

            if (this.original && this.changed) {
              this.original.setValue(original);
              this.goToLastLine(this.original);

              this.changed.setValue(changed);
              this.goToLastLine(this.changed);

              this.compare().then(() => {
                resolve();
              });
            }
          } else {
            this.initEditor();
          }
        } else {
          this.initEditor();
        }
      });
    });
  }

  componentDidMount() {
    const
      { match: { params } } = this.props;

    this.procedureShareReset();

    if (params.slug) {
      this.initBySlug(params.slug).then(() => {
        /*
         * Open share modal automaticcally
         */
        const
          modalShareOpen = localStorage.getItem('modalShareOpen');

        if (modalShareOpen) {
          localStorage.removeItem('modalShareOpen');

          this.modalShareShow();
        }
      });
    } else {
      this.initEditor();

      /*
       * Auto get example, compare and share
       */
      // setTimeout(() => {
      //   this.example();
      //   this.compare();
      // });
      // setTimeout(() => {
      //   this.upload();
      // }, 1500);
    }
  }

  goToHomePage() {
    this.props.history.push('/');
  }

  clear() {
    this.procedureShareReset().then(() => {
      if (this.original && this.changed) {
        this.original.setValue('');
        this.changed.setValue('');
        this.compare();
      }
    });
  }

  compare() {
    return new Promise(resolve => {
      if (this.original && this.changed) {
        this.setState({
          oldCode: this.original.getValue(),
          newCode: this.changed.getValue(),
        }, () => {
          resolve();
        });
      }
    });
  }

  example() {
    if (this.original && this.changed) {
      this.original.setValue(oldCode);
      this.goToLastLine(this.original);

      this.changed.setValue(newCode);
      this.goToLastLine(this.changed);
    }
  }

  procedureShareDisableAll() {
    return new Promise(resolve => {
      this.setState({
        procedureShareStatus: true,
      }, () => {
        setTimeout(() => {
          if (this.original) {
            this.original.setReadOnly(true);
          }
          if (this.changed) {
            this.changed.setReadOnly(true);
          }

          this.setState({
            procedureShareDisabledAllButton: true,
            procedureShareDisableStepClass: 'processing',
            procedureShareDisableStepLabel: 'Disabling',
            procedureShareProcessingNow: 100 / 3 * 1,
          });
        }, 0);

        setTimeout(() => {
          this.setState({
            procedureShareDisableStepClass: 'success',
            procedureShareDisableStepLabel: 'Disabled',
          }, () => {
            resolve();
          });
        }, 600);
      });
    });
  }

  procedureShareSaveData() {
    return new Promise(resolve => {
      this.setState({
        procedureShareSaveStepClass: 'processing',
        procedureShareSaveStepLabel: 'Saving',
      }, () => {
        if (this.state.oldCode && this.state.newCode) {
          diffCreate(this.state.oldCode, this.state.newCode).then(result => {
            if (result && result.data && (result.data.status)) {
              this.setState({
                procedureShareDisabledAllButton: false,
              });
              this.procedureShareErrorShow(result.message);
            } else if (typeof result === 'string') {
              this.setState({
                procedureShareProcessingNow: 100 / 3 * 2,
              });

              setTimeout(() => {
                this.setState({
                  procedureShareSaveStepClass: 'success',
                  procedureShareSaveStepLabel: 'Saved',
                }, () => {
                  resolve(result);
                });
              }, 600);
            }
          });
        }
      });
    });
  }

  procedureShareRedirect(slug) {
    this.setState({
      procedureShareRedirectStepClass: 'processing',
      procedureShareRedirectStepLabel: 'Redirecting',
      procedureShareProcessingNow: 100 / 3 * 3,
    }, () => {
      setTimeout(() => {
        localStorage.setItem('modalShareOpen', true);

        this.props.history.push('/' + slug);
      }, 600);
    });
  }

  procedureShareShowSuccessMessage(slug) {
    this.setState({
      procedureShareStatus: false,
    }, () => {
      this.procedureShareSuccessShow(slug);
    });
  }

  share() {
    const
      { match: { params } } = this.props;

    if (params.slug) {
      this.modalShareShow();
    } else {
      // 1. Disable all buttons & editors
      // 2. Save data
      // 3. Redirect to the new url
      // 4. Open share modal popup
      this.procedureShareReset().then(() => {
        return this.procedureShareDisableAll();
      }).then(() => {
        return this.procedureShareSaveData();
      }).then(slug => {
        this.procedureShareRedirect(slug);
      });
    }
  }

  upload() {
    const
      { match: { params } } = this.props;

    if (params.slug) {
      this.modalShareShow();
    } else {
      // 1. Disable all buttons & editors
      // 2. Save data
      // 3. Show success message
      this.procedureShareReset().then(() => {
        return this.procedureShareDisableAll();
      }).then(() => {
        return this.procedureShareSaveData();
      }).then(slug => {
        this.procedureShareShowSuccessMessage(slug);
      });
    }
  }

  highlightSyntax(str) {
    if (!str) {
      return str;
    }

    return str;

    // return (
    //   <pre
    //     style={{ display: 'inline' }}
    //     dangerouslySetInnerHTML={{ __html: Prism.highlight(str, Prism.languages.javascript) }}
    //   />
    // );
  }

  isAdministrator() {
    if (this.props.me && this.props.me.is_user_logged_in && this.props.me.roles) {
      if (this.props.me.roles.indexOf('administrator') !== -1) {
        return true;
      }
    }
    return false;
  }

  render() {
    const
      { match: { params } } = this.props;

    return (
      <>
        {!params.slug && (
          <Helmet>
            <title>Diff Online - An online diff tool can compare and find the difference between two text files</title>
            <meta property="og:title" content="Diff Online - An online diff tool can compare and find the difference between two text files" />
          </Helmet>
        )}

        {params.slug && (
          <Helmet>
            <title>{`Saved diff ${params.slug} - Diff Online`}</title>
            <meta property="og:title" content={`Saved diff ${params.slug} - Diff Online`} />
          </Helmet>
        )}

        {(!this.state.oldCode || !this.state.newCode) && (
          <Helmet>
            <meta
              name="description"
              content="Find the difference between 2 text files. Just paste the original and the changed text in respective boxes and click the Compare button. Share with others with just one click."
            />
            <meta property="og:description" content="Find the difference between 2 text files. Just paste the original and the changed text in respective boxes and click the Compare button. Share with others with just one click." />
          </Helmet>
        )}

        {this.state.oldCode && this.state.newCode && (
          <Helmet>
            <meta
              name="description"
              content={`${this.state.oldCode.substring(0, 100)} | ${this.state.newCode.substring(0, 100)}`}
            />
            <meta property="og:description" content={`${this.state.oldCode.substring(0, 100)} | ${this.state.newCode.substring(0, 100)}`} />
          </Helmet>
        )}

        {!this.state.ready && (
          <div className="do-loading text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <p>Fetching data...</p>
          </div>
        )}

        {this.state.ready && (
          <>
            {this.state.oldCode && this.state.newCode && (
              <section className="content container-fluid diff-checker">
                <div className="box box-success">
                  <div className="box-header with-border text-center">
                    <h2 className="box-title">Comparison</h2>
                    <div className="share absolute">
                      {!params.slug && this.isAdministrator() && (
                        <Button variant="light" className="mr-2" onClick={this.upload} disabled={this.state.procedureShareDisabledAllButton}>
                          <FontAwesomeIcon icon={faUpload} className="mr-2" />
                          <span>Upload</span>
                        </Button>
                      )}

                      <Button variant="success" onClick={this.share} disabled={this.state.procedureShareDisabledAllButton}>
                        <FontAwesomeIcon icon={faShare} className="mr-2" />
                        <span>Share</span>
                      </Button>
                    </div>

                    {this.state.procedureShareStatus && (
                      <div className="procedure">
                        <div className="row">
                          <div className={'col-4 text-center ' + this.state.procedureShareDisableStepClass}>
                            <p><FontAwesomeIcon icon={faRemoveFormat} /></p>
                            <p>{this.state.procedureShareDisableStepLabel} all buttons & editors</p>
                          </div>
                          <div className={'col-4 text-center ' + this.state.procedureShareSaveStepClass}>
                            <p><FontAwesomeIcon icon={faSave} /></p>
                            <p>{this.state.procedureShareSaveStepLabel} data</p>
                          </div>
                          <div className={'col-4 text-center ' + this.state.procedureShareRedirectStepClass}>
                            <p><FontAwesomeIcon icon={faDirections} /></p>
                            <p>{this.state.procedureShareRedirectStepLabel} to the new url</p>
                          </div>
                        </div>

                        <ProgressBar variant="success" animated now={this.state.procedureShareProcessingNow} />

                        <Alert className="mt-3" transition={false} show={this.state.procedureShareError} variant="danger">
                          <Alert.Heading>How's it going?!</Alert.Heading>
                          <p>
                            {this.state.procedureShareErrorMessage}
                          </p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <Button onClick={this.procedureShareReset} variant="outline-danger">
                              Close!
                            </Button>
                          </div>
                        </Alert>
                      </div>
                    )}

                    <Alert className="mt-3" transition={false} show={this.state.procedureShareSuccess} variant="success">
                      <Alert.Heading>Success!</Alert.Heading>
                      <p>
                        New Slug: <strong>{this.state.procedureShareSuccessSlug}</strong>
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button onClick={this.clear} variant="outline-success">
                          Close!
                        </Button>
                      </div>
                    </Alert>

                  </div>
                  <div className="box-body">
                    <div className="diff">
                      <ReactDiffViewer
                        oldValue={this.state.oldCode}
                        newValue={this.state.newCode}
                        splitView={true}
                        renderContent={this.highlightSyntax}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="content container-fluid diff-checker">
              <div className="row">
                <div className="col-sm-6">
                  <div className="box">
                    <div className="box-header with-border text-center">
                      <h3 className="box-title">Original Text</h3>
                    </div>
                    <div className="box-body">
                      <div id="original"></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="box">
                    <div className="box-header with-border text-center">
                      <h3 className="box-title">Changed Text</h3>
                    </div>
                    <div className="box-body">
                      <div id="changed"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="content container diff-checker pt-0">
              <div className="row">
                <div className="col-sm-4 text-center">
                  <Button className="mb-2" variant="info" onClick={this.example} disabled={this.state.procedureShareDisabledAllButton}>
                    <FontAwesomeIcon icon={faAlignLeft} className="mr-2" />
                    <span>Example</span>
                  </Button>
                </div>
                <div className="col-sm-4 text-center">
                  <Button className="mb-2" variant="primary" size="lg" onClick={this.compare} disabled={this.state.procedureShareDisabledAllButton}>
                    <FontAwesomeIcon icon={faNotEqual} className="mr-2" />
                    <span>Compare</span>
                  </Button>
                </div>
                <div className="col-sm-4 text-center">
                  <Button className="mb-2" variant="light" onClick={this.goToHomePage} disabled={this.state.procedureShareDisabledAllButton}>
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    <span>Clear all</span>
                  </Button>
                </div>
              </div>
            </section>

            <Modal show={this.state.modalShareStatus} onHide={this.modalShareHide} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Share this comparison</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup>
                  <FormControl
                    ref={(input) => this.inputCurrentURL = input}
                    defaultValue={window.location.href}
                  />
                  <InputGroup.Append>
                    <Button variant="outline-success" onClick={this.copyCurrentURL}>{this.state.buttonCopyText}</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default Home;