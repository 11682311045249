export async function commonGetMe() {
    const
        url = process.env.REACT_APP_API_URL + 'wp/me';

    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const result = await res.json();
        return result;
    }
    catch (error) {
        console.error(error);
    }
}