export async function diffGetBySlug(slug) {
  const
    url = process.env.REACT_APP_API_URL + 'wp/wp-json/wp/v2/diff?slug=' + slug;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
  }
}

export async function diffCreate(oldCode, newCode) {
  const
    url = process.env.REACT_APP_API_URL + 'wp/wp-json/diffonline/v1/diff';

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldCode: oldCode,
        newCode: newCode,
      }),
      credentials: 'include',
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
  }
}