// Packages
import { faAlignLeft, faNotEqual, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import React, { Component } from 'react';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

// Pages
import Home from './components/pages/Home/Home';

// Components
import GoogleAnalytics from './components/GoogleAnalytics';

// Css
import './App.css';

// Services
import { commonGetMe } from './components/services/Common';

class App extends Component {
  constructor(props) {
    super(props);

    const
      dt = new Date();

    this.state = {
      me: false,
      currentYear: dt.getFullYear(),
    }
  }

  componentDidMount() {
    commonGetMe().then(result => {
      if (result) {
        this.setState({
          me: result,
        });
      }
    });
  }

  render() {
    return (
      <Router>
        <header>
          <Container className="p-3 text-center">
            <h1 className="mb-0"><Link to="/"><span>Diff</span> Online</Link></h1>
          </Container>
        </header>

        <hr />
        <Switch>
          <Route
            exact
            path="/"
            key="home"
            render={
              (props) => <Home
                {...props}
                me={this.state.me}
              />
            }
          />
          <Route
            exact
            path="/:slug"
            key="slug"
            render={
              (props) => <Home
                {...props}
                me={this.state.me}
              />
            }
          />
        </Switch>

        <hr />

        <section className="content container diff-checker">
          <Alert variant="info" className="mb-0">
            <Alert.Heading>Hey, nice to see you, let's see what I can do for you</Alert.Heading>
            <hr />
            <ul>
              <li>
                Find the difference between 2 text files.
              </li>
              <li>
                Just paste the original and the changed text in respective boxes and click the <Button variant="primary" size="sm" disabled> <FontAwesomeIcon icon={faNotEqual} className="mr-2" /> Compare </Button> button.
              </li>
              <li>
                You can click the <Button variant="info" size="sm" disabled> <FontAwesomeIcon icon={faAlignLeft} className="mr-2" /> Example </Button> and then click the <Button variant="primary" size="sm" disabled> <FontAwesomeIcon icon={faNotEqual} className="mr-2" /> Compare </Button> button.
              </li>
              <li>
                <Button variant="success" size="sm" disabled> <FontAwesomeIcon icon={faShare} className="mr-2" /> Share </Button> with others with just one click.
              </li>
            </ul>
          </Alert>
        </section>

        <hr />

        <footer>
          <Container className="p-3 text-center">
            © {this.state.currentYear} <a href="https://diffonline.net/" title="Diff Online">diffonline.net</a>
          </Container>
        </footer>

        <GoogleAnalytics />
      </Router >
    );
  }
}

export default App;
